import { AccountHero as DefaultAccountHero } from 'components/src/AccountHero'
import * as React from 'react'

import DesktopBackgroundImage from './desktop.jpg'
import MobileBackgroundImage from './mobile.jpg'

const AccountHero = () => (
    <DefaultAccountHero
        image={DesktopBackgroundImage}
        imageMobile={MobileBackgroundImage}
    />
)

export default AccountHero
