import { useFormState } from 'informed'

import Button, { ButtonProps } from '../Button'

const Submit = (props: ButtonProps) => <Button {...props} type="submit" />

export const SubmitWithInformedValidation = (props: ButtonProps) => {
    const { errors } = useFormState()
    return <Submit {...props} disabled={Object.keys(errors).length > 0} />
}

export default Submit
