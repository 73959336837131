import { css } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'
import { maxWidth, minWidth } from '@emico/styles'

import usePrismicFooter from './prismicFooterData'
import {
    FooterCastironBody,
    FooterJbfoBody,
    FooterJustbrandsBody,
    FooterPmeBody,
    FooterPmeBodyServiceContactFields,
    FooterPmeBodyServiceContactPrimary,
    FooterPmeoutletBody,
    FooterVanguardBody,
} from '../../graphql/schema.generated'
import { getPrismicComponents } from '../../htmlSerializer'
import Container from '../../layout/Container'
import ChatIcon from '../../static/icons/chat.svg?react'
import MailIcon from '../../static/icons/mail.svg?react'
import PhoneIcon from '../../static/icons/phone.svg?react'
import WhatsappIcon from '../../static/icons/whatsapp.svg?react'
import theme from '../../theme'
import Heading from '../../typography/Heading'
import Text from '../../typography/Text'
import { useSocialButtonClickEvent } from '../../utils/ga4/useSocialButtonClickEvent'
import buttonClick from '../../utils/googleTagManager/buttonClick'
import push from '../../utils/googleTagManager/push'

type FooterBody =
    | FooterPmeBody
    | FooterJustbrandsBody
    | FooterCastironBody
    | FooterVanguardBody
    | FooterJbfoBody
    | FooterPmeoutletBody

const Block = styled.div<{ alignment: string; hasClickAction?: boolean }>`
    display: flex;
    align-items: center;
    flex: 1 1 20%;

    &:first-of-type {
        flex: 0 0 33%;
        align-items: flex-start;
    }

    ${(props) =>
        props.alignment === 'vertical'
            ? 'flex-direction: column'
            : 'flex-direction: row'};
    ${(props) => (props.hasClickAction ? 'cursor: pointer' : '')};

    @media ${maxWidth('sm')} {
        flex: 0 0 100%;
    }
`

const BlockContainer = styled.div`
    display: flex;
    align-content: center;
    gap: 20px;

    @media ${maxWidth('sm')} {
        flex-direction: column;
        gap: 30px;
    }
`

const paragraphStyles = css`
    margin-top: 10px;
`

const StyledParagraph = styled(Text)`
    ${paragraphStyles}
`

const FooterTopContainer = styled.div`
    padding-top: 45px;
    padding-bottom: 45px;

    @media ${maxWidth('sm')} {
        padding-top: 25px;
        padding-bottom: 25px;
    }
`

const IconContainer = styled.div`
    margin-right: 20px;

    svg,
    img {
        fill: ${theme.colors.text};
        min-width: 30px;
        max-width: 50px;
        width: 100%;
        height: auto;

        @media ${maxWidth('md')} {
            max-width: 40px;
        }
    }

    img {
        filter: grayscale(100%) invert(1);
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

const StyledHeading = styled(Heading)`
    &::before {
        display: none;
    }
    &::after {
        display: none;
    }
`

const ContactOptionTitle = styled(StyledHeading)`
    font-size: 16px;
`

const WhatsappLink = styled.a`
    text-decoration: none;
    color: inherit !important;
`

const Wrapper = styled.div`
    background: ${theme.colors.footerTopBackground};
`

const Separator = styled.div`
    @media ${minWidth('md')} {
        border-bottom: 1px solid
            var(--color-footer-divider, --color-footer-top-background);
    }
`

const ServiceContact = [
    'FooterPmeBodyServiceContact',
    'FooterJustbrandsBodyServiceContact',
    'FooterCastironBodyServiceContact',
    'FooterVanguardBodyServiceContact',
    'FooterJbfoBodyServiceContact',
    'FooterPmeoutletBodyServiceContact',
    'FooterMonstersaleBodyServiceContact',
]

const FooterTop = () => {
    let serviceAndContactData
    const { data } = usePrismicFooter()
    const body = data?.body as FooterBody[]
    const pushEvent = useSocialButtonClickEvent()

    if (data && body) {
        serviceAndContactData = body.find((item) => {
            if (item.__typename) {
                return ServiceContact.includes(item.__typename)
            }
            return null
        }) as FooterBody
    }
    if (!serviceAndContactData) {
        return null
    }
    if (!serviceAndContactData?.primary) {
        return null
    }
    const { content, contactContent, contactTitle } =
        serviceAndContactData?.primary as FooterPmeBodyServiceContactPrimary

    const fields =
        (serviceAndContactData.fields as FooterPmeBodyServiceContactFields[]) ??
        []

    const handleElementClick = (type: string) => () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!window.__robin) {
            return
        }

        switch (type) {
            case 'phone':
                push(buttonClick('BEL', 'Bel Met Ons'))
                pushEvent('footer', 'bel')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // Super-duper ugly heck to prevent robin script to open the current url in the robin iframe:
                // Show another form first, directly followed by the actual form we need here.
                window.__robin.show('contact_form')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.__robin.show('phone')
                break
            case 'chat':
                push(buttonClick('chat', 'contactformulier'))
                pushEvent('footer', 'chat')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.__robin.show('chat')
                break
            case 'email':
                pushEvent('footer', 'mail')
                push(buttonClick('mail', 'contactformulier'))
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.__robin.show('contact_form')
                break
            case 'whatsapp':
                pushEvent('footer', 'whatsapp')
                push(buttonClick('whatsapp', 'contactformulier'))
                break
        }
    }

    return (
        <Wrapper>
            <FooterTopContainer>
                <Container>
                    <BlockContainer>
                        <Block alignment="vertical">
                            {contactTitle && (
                                <StyledHeading element="h2" variant="h3">
                                    {contactTitle}
                                </StyledHeading>
                            )}
                            {!content && contactContent && (
                                <StyledParagraph>
                                    {contactContent}
                                </StyledParagraph>
                            )}
                            {content && content.length > 0 && (
                                // eslint-disable-next-line react/no-unknown-property
                                <div css={paragraphStyles}>
                                    <PrismicRichText
                                        render={
                                            content as [
                                                RichTextBlock,
                                                ...RichTextBlock[],
                                            ]
                                        }
                                        components={getPrismicComponents({})}
                                        analyticsContext="prismic.footertop.content"
                                    />
                                </div>
                            )}
                        </Block>
                        {fields?.map((field, index) => {
                            const { title, linkType, content } = field

                            if (!linkType || linkType === 'none') {
                                return null
                            }

                            let icon
                            let label: React.ReactNode = title

                            switch (linkType) {
                                case 'email':
                                    icon = <MailIcon />
                                    break
                                case 'phone':
                                    icon = <PhoneIcon />
                                    break
                                case 'chat':
                                    icon = <ChatIcon />
                                    break
                                case 'whatsapp': {
                                    if (
                                        !title ||
                                        !content ||
                                        !/^(\+)?(\d| |\(0\))+$/.test(content)
                                    ) {
                                        return null
                                    }

                                    const makeWhatsappLink = (
                                        element: React.ReactNode,
                                    ) => (
                                        <WhatsappLink
                                            target="_blank"
                                            href={`https://api.whatsapp.com/send?phone=${content
                                                .replace(/\(0\)| |\+/g, '')
                                                .replace(
                                                    /^0+/,
                                                    '',
                                                )}&text=${encodeURI(title)}`}
                                        >
                                            {element}
                                        </WhatsappLink>
                                    )

                                    label = makeWhatsappLink(content)
                                    icon = makeWhatsappLink(<WhatsappIcon />)
                                    break
                                }
                                default:
                                    return null
                            }

                            return (
                                <Block
                                    alignment="horizontal"
                                    key={index}
                                    hasClickAction
                                    onClick={handleElementClick(linkType)}
                                >
                                    <IconContainer>{icon}</IconContainer>
                                    <ContentContainer>
                                        <ContactOptionTitle
                                            element="h3"
                                            variant="h4"
                                        >
                                            {label}
                                        </ContactOptionTitle>
                                        <Text color="light">
                                            {field.subText}
                                        </Text>
                                    </ContentContainer>
                                </Block>
                            )
                        })}
                    </BlockContainer>
                </Container>
            </FooterTopContainer>
            <Container>
                <Separator />
            </Container>
        </Wrapper>
    )
}

export default FooterTop
