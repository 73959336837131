import { CartItemFragment, FreeGiftRule } from '@emico-hooks/cart-fragment'
import { useIsLoggedIn } from '@emico-hooks/login-token'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'

import styles from './cartgiftitem.module.scss'
import cartStyles from './CartItem/CartItem.module.scss'
import CartItemImage from './CartItemImage'
import CartItemQuantityForm from './CartItemQuantityForm'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { useGetBasicProduct } from '../catalog/ProductPage/GetBasicProduct.query'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import { LazyProductPage } from '../chunks'
import { PRIVILEGES_ENABLED } from '../constants'
import { Responsive } from '../core/Responsive'
import { EditGiftAppliedButton } from '../gifts/EditGiftAppliedButton'
import { EditGiftDeselectButton } from '../gifts/EditGiftDeselectButton'
import { EditGiftSelectButton } from '../gifts/EditGiftSelectButton'
import { FreeGiftNoSelect } from '../gifts/FreeGiftNoSelect'
import { FreeGiftProductSelection } from '../gifts/FreeGiftProductSelection'
import { FreeGiftSizeSelector } from '../gifts/FreeGiftSizeSelector'
import Link from '../navigation/Link'
import Price from '../presentation/Price'
import { ProgressBarLoyalty } from '../progress/ProgressBarLoyalty'
import Heading from '../typography/Heading'
import Text from '../typography/Text'
import parseMagentoDateTime from '../utils/parseMagentoDateTime'

type SelectionRef = React.ElementRef<
    typeof FreeGiftSizeSelector | typeof FreeGiftNoSelect
>

const CartGiftItem = ({
    productId,
    cartItem,
    rule,
    className,
    hasGiftSelection,
    isSelectionDisabled,
    onChange,
    hideAmount,
    hideFreeGiftLabel,
    editMode = false,
    hideSizeSelector = false,
}: {
    productId: number
    cartItem?: CartItemFragment
    rule?: FreeGiftRule
    withSku?: boolean
    className?: string
    hasGiftSelection?: boolean
    hideSelect?: boolean
    isSelectionDisabled?: boolean
    onChange?: (
        option: ConfigurableAttributesFieldValue | undefined,
        product: ConfigurableProduct | Product,
    ) => void
    hideAmount?: boolean
    hideFreeGiftLabel?: boolean
    editMode?: boolean
    hideSizeSelector?: boolean
}) => {
    const { data: product, loading } = useGetBasicProduct(productId)

    const [showProgress, setShowProgress] = useState<boolean>(false)
    const [isRuleCompleted, setIsRuleCompleted] = useState<boolean>()

    const isLoggedIn = useIsLoggedIn()

    const selectionRef = useRef<SelectionRef>(null)

    const [isEmptyOptionValue, setIsEmptyOptionValue] = useState<boolean>()

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    const isValidCustomerGroup =
        !(!isLoggedIn && !rule?.isValidCustomerGroup) ||
        rule?.isValidCustomerGroup

    const { insufficientAmount, isValidConditions } = rule || {}

    const configurableProduct: ConfigurableProduct | undefined = product
        ? (product as unknown as ConfigurableProduct)
        : undefined

    const isSingleSizeProduct =
        product?.__typename === 'SimpleProduct' ||
        configurableProduct?.variants?.length === 1

    const productCartItem = cartItem?.product

    useLayoutEffect(() => {
        if (
            !loading &&
            configurableProduct &&
            (Boolean(insufficientAmount?.value) || !productCartItem)
        ) {
            setTimeout(() => setShowProgress(true), 0)
        } else {
            if (productCartItem) {
                setShowProgress(false)
            }
        }
    }, [configurableProduct, loading, insufficientAmount, productCartItem])

    useEffect(() => {
        const ruleCompleted =
            isValidConditions &&
            Boolean(configurableProduct) &&
            Boolean(!productCartItem)
        setIsRuleCompleted(ruleCompleted)
    }, [productCartItem, isValidConditions, configurableProduct])

    const remainingAmount = <Price price={insufficientAmount?.value ?? 0} />

    const onChangeCallback = useCallback(
        (option: ConfigurableAttributesFieldValue) => {
            setIsEmptyOptionValue(!option || Object.keys(option).length < 1)
            onChange?.(option, product as unknown as ConfigurableProduct)
        },
        [onChange, product],
    )

    if (!product || !rule) {
        return null
    }

    const enableEditItem =
        isValidCustomerGroup &&
        isValidConditions &&
        !cartItem &&
        isSingleSizeProduct
    const canEdit = editMode || enableEditItem
    const cartItemProduct = cartItem ? cartItem.product : product

    return (
        <>
            <div
                className={classNames(
                    styles.card,
                    {
                        [styles.completed]: isRuleCompleted && isLoggedIn,
                        [styles.applied]: cartItem && rule?.isValidConditions,
                        [styles.editMode]: editMode,
                    },
                    PRIVILEGES_ENABLED ? styles.giftBg : '',
                    className,
                )}
            >
                <div className={styles.column}>
                    <div className={styles.productImage}>
                        <CartItemImage product={product} />
                    </div>
                </div>
                <div
                    className={
                        !cartItem && !editMode ? styles.center : styles.column
                    }
                >
                    <div className={styles.header}>
                        <div className={styles.info}>
                            {/* Cart items have different ui */}
                            {!cartItem ? (
                                <>
                                    <Heading variant="h3" element="h2">
                                        {product.name}
                                    </Heading>
                                    <Text
                                        as="span"
                                        color="dark"
                                        className={classNames(
                                            !editMode ? styles.ruleLabel : '',
                                        )}
                                    >
                                        {rule?.unclaimedLabel || rule?.label}
                                    </Text>
                                    {rule?.validUntil && (
                                        <>
                                            <Text
                                                color="grey"
                                                className={styles.validDate}
                                            >
                                                <Trans id="user.rewards.validUntil">
                                                    Valid until:
                                                </Trans>{' '}
                                                <span>
                                                    {new Intl.DateTimeFormat(
                                                        i18n.locale,
                                                        {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                        },
                                                    ).format(
                                                        new Date(
                                                            parseMagentoDateTime(
                                                                rule.validUntil ??
                                                                    '',
                                                            ),
                                                        ),
                                                    )}
                                                </span>
                                            </Text>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Text as="h1" color="dark">
                                        <Link
                                            to={`/${product.urlKey}`}
                                            name={product.name}
                                            category="cart.cartPage.cartItem"
                                            onMouseEnter={handleMouseEnter}
                                            variant="dark"
                                        >
                                            {product.name}
                                        </Link>
                                    </Text>
                                    <span
                                        className={
                                            !editMode ? styles.ruleLabel : ''
                                        }
                                    >
                                        {rule?.claimedLabel || rule?.label}
                                    </span>
                                </>
                            )}
                        </div>
                        {!hideFreeGiftLabel && cartItem && (
                            <Responsive md up>
                                <div
                                    className={classNames(
                                        styles.priceContainer,
                                        styles.freegift,
                                    )}
                                >
                                    <Trans id="cart.cartItem.freeGiftLabel">
                                        Free gift
                                    </Trans>
                                </div>
                            </Responsive>
                        )}
                    </div>

                    {/* Show gift (size) selector: with or without size selector. */}
                    <div
                        className={classNames(
                            styles.configuration,
                            editMode ? styles.editMode : '',
                        )}
                    >
                        {isSingleSizeProduct && isValidConditions ? (
                            <FreeGiftNoSelect
                                ref={selectionRef}
                                product={
                                    product as unknown as ConfigurableProduct
                                }
                                cartItem={cartItem}
                                rule={rule}
                                onChange={onChangeCallback}
                                editMode={editMode}
                            />
                        ) : (
                            isValidCustomerGroup &&
                            isValidConditions &&
                            cartItemProduct &&
                            !hideSizeSelector && (
                                <FreeGiftSizeSelector
                                    editMode={canEdit}
                                    ref={selectionRef}
                                    onChange={onChangeCallback}
                                    disabled={isSelectionDisabled}
                                    cartItem={cartItem}
                                    product={cartItemProduct}
                                    isCartItem={Boolean(cartItem?.product)}
                                />
                            )
                        )}
                        {isValidCustomerGroup &&
                            isValidConditions &&
                            cartItem &&
                            !hideAmount && (
                                <>
                                    <span className={cartStyles.amount}>
                                        <label>
                                            <Trans id="cart.cartItem.amountLabel">
                                                Amount
                                            </Trans>
                                        </label>
                                        <CartItemQuantityForm
                                            disabled
                                            quantity={1}
                                            onSubmit={() => undefined}
                                            max={1}
                                        />
                                    </span>
                                </>
                            )}
                    </div>
                    {!cartItem && !editMode && (
                        <div className={styles.progress}>
                            <ProgressBarLoyalty
                                insufficientAmount={
                                    insufficientAmount?.value ?? 0
                                }
                                isCompleted={isRuleCompleted}
                                showProgress={showProgress}
                            >
                                {isRuleCompleted ? (
                                    <Trans id="cart.gift.rule.completed">
                                        Completed
                                    </Trans>
                                ) : (
                                    <Trans id="cart.nextGiftRule.priceText">
                                        {remainingAmount} to go
                                    </Trans>
                                )}
                            </ProgressBarLoyalty>
                        </div>
                    )}

                    {/* In non edit mode, show change gift and free gift label */}
                    {!editMode && isValidCustomerGroup && (
                        <div
                            className={classNames(
                                styles.column,
                                styles.editGift,
                            )}
                        >
                            {hasGiftSelection && (
                                <FreeGiftProductSelection
                                    rule={rule}
                                    ruleCompleted={
                                        Boolean(isRuleCompleted) ||
                                        Boolean(rule.itemIds?.length)
                                    }
                                />
                            )}
                            {cartItem && (
                                <Responsive sm down>
                                    <div
                                        className={classNames(
                                            styles.priceContainer,
                                            styles.freegift,
                                        )}
                                    >
                                        <Trans id="cart.cartItem.freeGiftLabel">
                                            Free gift
                                        </Trans>
                                    </div>
                                </Responsive>
                            )}
                        </div>
                    )}

                    {/* In desktop edit mode (EditGiftModal), show 'unlock' message */}
                    <Responsive md up>
                        {canEdit && isSelectionDisabled && (
                            <div className={classNames(styles.otherGift)}>
                                <Trans id="cart.gifts.modal.unlockGift">
                                    Prefer this gift? Deselect another gift
                                    first.
                                </Trans>
                            </div>
                        )}
                    </Responsive>
                </div>

                {/* In edit mode (EditGiftModal) render buttons for (de-)selection */}
                {editMode && (
                    <div
                        className={classNames(
                            styles.column,
                            styles.selectButtons,
                        )}
                    >
                        <div className={styles.toggleSelection}>
                            {/* No selection made */}
                            {isEmptyOptionValue && (
                                <EditGiftSelectButton
                                    className={styles.cartItemButton}
                                    disabled={isSelectionDisabled}
                                    onClick={() => {
                                        if (selectionRef?.current?.select) {
                                            selectionRef.current.select(
                                                !editMode && enableEditItem,
                                            )
                                        }
                                    }}
                                />
                            )}
                            {/* Selection is made */}
                            {!isEmptyOptionValue && !isSelectionDisabled && (
                                <>
                                    <EditGiftAppliedButton
                                        minWidth
                                        className={classNames(
                                            styles.cartItemButton,
                                            styles.applied,
                                        )}
                                    />
                                    <EditGiftDeselectButton
                                        onClick={() =>
                                            selectionRef.current?.deselect()
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                )}
                {/* In mobile edit mode (EditGiftModal), show 'unlock' message */}
                <Responsive sm down>
                    {canEdit && isSelectionDisabled && (
                        <div
                            className={classNames(
                                styles.column,
                                styles.otherGift,
                            )}
                        >
                            <Trans id="cart.gifts.modal.unlockGift">
                                Prefer this gift? Deselect another gift first.
                            </Trans>
                        </div>
                    )}
                </Responsive>
            </div>
        </>
    )
}

export default CartGiftItem
