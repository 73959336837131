import {
    CartFragment,
    CartItemFragment,
    FreeGiftRule,
} from '@emico-hooks/cart-fragment'
import { CurrencyEnum } from '@emico-hooks/graphql-schema-types'

import { Product } from '../catalog/ProductPage/GetProduct.query'
import { SrsVoucher } from '../graphql/schema.generated'

export function mapSrsFreeGiftVouchersToFreeGift(
    cart: CartFragment | undefined | null,
    item: SrsVoucher,
): FreeGiftRule {
    // Map the srs voucher to a FreeGiftRule, so we can treat it as a 'normal' CartGiftItem.
    const mappedVoucher: FreeGiftRule = {
        isActive: item.freeGiftRule?.isActive ?? true,
        isAvailable: item.freeGiftRule?.isAvailable ?? true,
        isValid: item.freeGiftRule?.isActive ?? true,
        isValidCustomerGroup: true, // srs vouchers are always valid for the signed in customer.
        products: item.freeGiftRule?.products
            ? (item.freeGiftRule.products.map((prod) => ({
                  id: prod?.uid ?? 0,
                  type: '',
              })) as unknown as Product[])
            : [],
        id: item.id,
        itemIds: [],
        claimedLabel:
            item.freeGiftRule?.claimedLabel ||
            item.freeGiftRule?.label ||
            item.freeGiftRule?.name ||
            '',
        unclaimedLabel:
            item.freeGiftRule?.unclaimedLabel ||
            item.freeGiftRule?.label ||
            item.freeGiftRule?.name ||
            '',
        insufficientAmount: {
            currency: item.minPurchaseAmount?.currency ?? CurrencyEnum.EUR,
            value: Math.max(
                0,
                (item.minPurchaseAmount?.value || 0) / 100 -
                    (cart?.prices?.subtotalIncludingTax?.value ?? 0),
            ),
        },
        maxItems: item.maxProductQty ?? 0,
        validUntil: new Date(item.validTill ?? '').toISOString(),
        __typename: 'FreeGiftRule',
        name: item?.freeGiftRule?.name ?? '',
        sortOrder: item.freeGiftRule?.sortOrder ?? 0,
        tooltip: item?.freeGiftRule?.tooltip ?? '',
    }

    const items =
        item.freeGiftRule?.products?.map((prod) => ({
            productUid: prod?.uid ?? '',
            productId: prod?.id ?? 0,
            item: cart?.items?.find(
                (item) => item?.product?.uid === prod?.uid,
            ) as CartItemFragment,
        })) ?? []

    const itemIds = items?.map((item) => item.item?.id).filter(Boolean)

    return {
        ...mappedVoucher,
        items,
        isValidConditions: mappedVoucher.insufficientAmount?.value === 0,
        itemIds,
        isCartItem: items.length > 0 && itemIds.length > 0,
    }
}
