import { makeVar, useReactiveVar } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { useCartItems } from './cart/CartPage/useCartItems'
import { useMiniCartVar } from './cart/miniCartVar'
import EditGiftsModal from './gifts/EditGiftsModal'
import paths from './paths'

export const giftModalEnabledVar = makeVar<boolean>(false)

const FreeGiftAvailableModal = () => {
    const { pathname } = useLocation()

    const { freeGifts } = useCartItems()
    const availableRules = freeGifts?.filter(
        (gift) => gift.isAvailable && gift.isValid,
    )

    const isModalEnabled = useReactiveVar(giftModalEnabledVar)
    const { open } = useMiniCartVar()

    // Do not show minicart on cart page
    const disableMiniCart = pathname === paths.cart

    if (!isModalEnabled || !availableRules?.length) {
        return null
    }

    return (
        <EditGiftsModal
            rules={availableRules}
            onBack={() => {
                giftModalEnabledVar(false)
                if (!disableMiniCart) {
                    open()
                }
            }}
        />
    )
}

export default FreeGiftAvailableModal
