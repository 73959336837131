import { defineMessage } from '@lingui/macro'
import makeUrl from 'components/src/makeUrl'

import { getActiveStoreView, setStoreViews, StoreView } from '@emico/storeviews'

const storeViews: Array<
    StoreView & {
        prismicLocale?: string
    }
> = [
    {
        code: 'vanguard_nl',
        language: 'nl',
        locale: 'nl-nl',
        name: defineMessage({
            id: 'core.storeConfig.vanguard_nl',
            message: `Netherlands`,
        }),
        location: undefined,
        makeUrl: makeUrl(),
        basename: '',
        default: true,
        storeId: 12,
        websiteId: 5,
        prismic: 'vanguard',
        prismicLocale: 'nl-nl',
    },
    {
        code: 'vanguard_de',
        language: 'de',
        locale: 'de-de',
        name: defineMessage({
            id: 'core.storeConfig.vanguard_de',
            message: `Germany`,
        }),
        location: (location) =>
            location.pathname === '/de' || location.pathname.startsWith('/de/'),
        makeUrl: makeUrl('de'),
        basename: '/de',
        default: false,
        storeId: 26,
        websiteId: 10,
        prismic: 'vanguard',
        prismicLocale: 'de-de',
    },
    {
        code: 'vanguard_be',
        language: 'be',
        locale: 'nl-be',
        name: defineMessage({
            id: 'core.storeConfig.vanguard_be',
            message: `Belgium`,
        }),
        location: (location) =>
            location.pathname === '/be' || location.pathname.startsWith('/be/'),
        makeUrl: makeUrl('be'),
        basename: '/be',
        default: false,
        storeId: 14,
        websiteId: 6,
        prismic: 'vanguard',
        prismicLocale: 'nl-be',
    },
]

const activeStoreView = getActiveStoreView(storeViews, window.location)
setStoreViews(storeViews, activeStoreView)

export default storeViews
