import { CustomerCoupon } from '../account-dashboard-page/AccountDashboardPageLayout/justRewards/Vouchers/customerVouchers.query'
import {
    AppliedCoupon,
    CurrencyEnum,
    SrsVoucher,
} from '../graphql/schema.generated'

export const mapSrsSalesRuleVoucherToCustomerCoupon = (
    appliedVouchers: AppliedCoupon[],
    item: SrsVoucher,
): CustomerCoupon => ({
    description: item.description ?? '',
    code: item.barcode,
    amount: {
        value: item.valueAmount ? item.valueAmount.value : 0,
        currency: item.valueAmount?.currency || CurrencyEnum.EUR,
    },
    valueCurrency: item.valueAmount?.value,
    valuePercentage: item.valuePercentage || 0,
    couponId: `${item.id}`,
    applied: appliedVouchers?.find((voucher) => voucher.code === item.barcode)
        ? item.redeemedAt || Date.now().toString()
        : '',
    isActive: appliedVouchers?.find((voucher) => voucher.code === item.barcode)
        ? true
        : false,
    minimalOrderAmount: {
        currency: item.minPurchaseAmount?.currency ?? CurrencyEnum.EUR,
        value: appliedVouchers?.find((voucher) => voucher.code === item.barcode)
            ? 0
            : item.minPurchaseAmount
              ? item.minPurchaseAmount.value / 100
              : 0,
    },
    originalMinimalOrderAmount: item.minPurchaseAmount
        ? item.minPurchaseAmount.value / 100
        : 0,
    __typename: 'CustomerCoupon',
    type: {
        description: '',
        image: item.salesRule?.image ?? '',
        title: '',
        __typename: 'CustomerCouponType',
    },
    validUntil: new Date(item.validTill ?? '').toISOString(),
})
