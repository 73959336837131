import { useCart } from '@emico-hooks/cart'
import { FreeGiftRule } from '@emico-hooks/cart-fragment'
import { AppliedCoupon } from '@emico-hooks/graphql-schema-types'
import { useIsLoggedIn } from '@emico-hooks/login-token'

import { CustomerCoupon, useGetCustomerCoupons } from './customerVouchers.query'
import { mapSrsSalesRuleVoucherToCustomerCoupon } from './utils'
import {
    useGetAppliedCoupons,
    useSrsVouchersFreeGift,
    useSrsVouchersSalesRule,
} from '../customer/srsVouchers.query'
import { mapSrsFreeGiftVouchersToFreeGift } from '../gifts/utils'

interface VouchersResult {
    loading: boolean
    salesRuleVouchers: CustomerCoupon[]
    customerCoupons: CustomerCoupon[]
    freeGiftVouchers: FreeGiftRule[]
}

interface Props {
    skipFreeGifts?: boolean
    skipCustomerCoupons?: boolean
}

export const useGetCustomerVouchers = (props?: Props): VouchersResult => {
    const isLoggedIn = useIsLoggedIn()
    const { skipCustomerCoupons, skipFreeGifts } = props ?? {}

    const { data, loading: loadingCart } = useCart()

    // const { data: appliedVouchers, loading: loadingAppliedCoupons } =
    //     useGetAppliedCoupons({
    //         skip: !isLoggedIn || skipCustomerCoupons,
    //     })

    const appliedVouchers: AppliedCoupon[] =
        data?.appliedCoupons?.map((coupon) => ({
            ...coupon,
            code: coupon?.code ?? '',
        })) ?? []

    const { vouchers: salesRuleVouchers, loading: loadingSalesRules } =
        useSrsVouchersSalesRule({
            skip: !isLoggedIn || skipCustomerCoupons,
        })

    const { data: vouchers, loading: loadingCustomerCoupons } =
        useGetCustomerCoupons({ skip: skipCustomerCoupons })

    const { vouchers: freeGiftVouchers, loading: loadingFreeGiftRules } =
        useSrsVouchersFreeGift({
            skip: !isLoggedIn || skipFreeGifts,
        })

    return {
        customerCoupons: vouchers || [],
        salesRuleVouchers: salesRuleVouchers.map((v) =>
            mapSrsSalesRuleVoucherToCustomerCoupon(appliedVouchers, v),
        ),
        freeGiftVouchers: freeGiftVouchers
            .map((v) => mapSrsFreeGiftVouchersToFreeGift(data, v))
            // In some cases freegift vouchers have no product(s) configured in Magento, so filter those out:
            .filter((gift) => gift.products?.length),
        loading:
            loadingSalesRules ||
            loadingFreeGiftRules ||
            loadingCustomerCoupons ||
            loadingCart,
    }
}
