import styled from '@emotion/styled'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'
import { useBreakpoints } from '@emico/ui'

import { getPrismicComponents } from './htmlSerializer'
import Button from './input/Button'
import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import getLayoutStyleVars, { LayoutStyle } from './LayoutStyle'
import styles from './PrismicTextOnly.module.scss'
import theme from './theme'
import ButtonVariant from './types/ButtonVariant'
import HeadingVariant from './types/HeadingVariant'
import Heading from './typography/Heading'

export interface Props {
    style: LayoutStyle
    tablet?: string
    desktop?: string
    heading?: string
    headingVariant?: string | HeadingVariant
    content?: unknown
    buttonText?: string
    buttonUrl?: string
    visibility?: string
}

const Section = styled.section`
    background: ${theme.colors.background};
`

const PrismicTextOnly = ({
    style,
    heading,
    headingVariant = HeadingVariant.h2,
    content,
    tablet,
    desktop,
    buttonText,
    buttonUrl,
    visibility,
}: Props) => {
    const { isMobile } = useBreakpoints()
    const viewport = isMobile ? 'mobile' : 'desktop'

    if (visibility && !visibility?.includes(viewport)) {
        return null
    }

    if (!heading && !content) {
        return null
    }

    const layoutStyle = getLayoutStyleVars(style)

    const buttonColorByTheme: ButtonVariant =
        style === 'light' ? ButtonVariant.primary : ButtonVariant.white

    return (
        <Section css={layoutStyle}>
            <Container className={styles.container}>
                <Row className={styles.row}>
                    <Col
                        xs={{ order: 0, span: 12, offset: 0 }}
                        md={{ order: 0, span: 8, offset: 2 }}
                        lg={{ order: 0, span: 6, offset: 3 }}
                        className={styles.column}
                    >
                        {heading && (
                            <Heading
                                variant={headingVariant as HeadingVariant}
                                element="h2"
                                className={styles.heading}
                            >
                                {heading}
                            </Heading>
                        )}

                        {content && (
                            <PrismicRichText
                                render={content as [RichTextBlock]}
                                components={getPrismicComponents({
                                    contentClassName: styles.text,
                                })}
                                analyticsContext="prismic.textonly.content"
                            />
                        )}
                        {buttonText && buttonUrl && (
                            <Button
                                minWidth
                                to={buttonUrl}
                                variant={buttonColorByTheme}
                                className={styles.button}
                                name={buttonText}
                                category="prismic.textonly.button"
                            >
                                {buttonText}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default PrismicTextOnly
