enum HeadingVariant {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    subheading = 'subheading',
    subheadingAlt = 'subheadingAlt',
    sale = 'sale',
    story = 'story',
}

export default HeadingVariant
